import React,{useState, useEffect} from 'react'
import { Layout, Navbar, Seo, BigMenu, AppointmentScheduler } from '../components'

const CraftBeer = ({location}) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Seo title={'Craft Beer - On Premise Crafting'}>
            <Layout>
                <Navbar/>
                <BigMenu/>
                <AppointmentScheduler
                 addToSubmit={location && location.state && location.state.item !== undefined ? {product:location.state.item} : null} 
                 minTime={'10:00'} 
                 maxTime={'17:30'} 
                 timeArr={
                     {
                        monday:{min:'10:00',max:'17:30'},
                        tuesday:{min:'10:00',max:'17:30'},
                        wednesday:{min:'10:00',max:'17:30'},
                        thursday:{min:'10:00',max:'17:30'},
                        friday:{min:'10:00',max:'17:30'},
                        saturday:{min:'10:00',max:'14:00'}
                    }
                }
                 birth 
                 prompt="To craft on premise use this form to book an appointment"/>
            </Layout>
        </Seo>
    )
}

export default CraftBeer